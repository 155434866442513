import { queryOptions, type QueryClient, type QueryKey } from "@tanstack/react-query";
import type { ReferralEnabled } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { raiseRequestToast, type Toast } from "../../modules/notifications/toast.tsx";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../utils/query/runMutation.ts";

export const queryKey: QueryKey = ["/payment/admin/referral_enabled"];
const toastOptions: Partial<Toast> = { icon: "" };

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<ReferralEnabled> => {
            return getResponseData(await client.GET("/payment/admin/referral_enabled", { signal }));
        },
    });
}

export const referralEnabledQueryAtom = atomFromStandardQueryOptions(getQuery);

//#region Mutations
export function toggleEnabledMutation(apiClient: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["/payment/admin/referral_enabled/toggle"],
        async mutationFn({ enabled }: { enabled: boolean }) {
            return getResponseData(
                await apiClient.PUT("/payment/admin/referral_enabled", {
                    ...jsonEncodedBody,
                    body: { enabled: enabled },
                }),
            );
        },

        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Referral program updated",
                error: "Failed to updated referral program",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

//#endregion Mutations
